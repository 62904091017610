import React from 'react';
import { ZigInputCode, ZigButton, ZigTypography } from '@zignaly-open/ui';
import { useTranslation } from 'react-i18next';
import { Layout, Field } from './styles';
import { EmailVerifyFormProps } from './types';
import { Box } from '@mui/material';

function EmailVerifyForm({
  onSubmit,
  onChange,
  onReSendCode,
  clearOnError,
  isLoading,
  error = null,
  isReSendLoading = false,
  prefixId,
}: EmailVerifyFormProps) {
  const { t } = useTranslation('auth');
  return (
    <Layout>
      <Field>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <ZigTypography variant={'body1'} id={`${prefixId}__description`}>
            {t('login-form.verifyEmail.title')}
          </ZigTypography>
          <ZigTypography variant={'body2'} id={`${prefixId}__description`}>
            {t('login-form.verifyEmail.spam')}
          </ZigTypography>
        </Box>
        <ZigInputCode
          prefixId={prefixId}
          fields={6}
          error={error}
          loading={isLoading}
          clearOnError={clearOnError}
          onComplete={onSubmit}
          onChange={onChange}
          autoFocus={true}
        />
        <ZigButton
          id={`${prefixId}__resend-code`}
          variant={'text'}
          onClick={onReSendCode}
          loading={isReSendLoading}
        >
          {t('login-form.verifyEmail.button')}
        </ZigButton>
      </Field>
    </Layout>
  );
}

export default EmailVerifyForm;
