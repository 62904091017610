import React from "react";
import { Layout, Container, ZigMenuItemSubHeader } from "./styles";
import { MarginContainer } from "../../styled";
import { SubHeaderMenuButton, SubHeaderOption } from "./atoms";
import { ZigSubHeaderRoute } from "./types";
import { SxProps } from "@mui/system";
import ZigMenu from "components/display/ZigMenu";
import { useMediaQuery, useTheme } from "@mui/material";

function ZigSubHeader({
  routes,
  sx,
  menuSx,
}: {
  sx?: SxProps;
  menuSx?: SxProps;
  routes: ZigSubHeaderRoute[];
}) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const optionSx = {
    padding: "7px 28px",
    height: sm ? "unset" : "56px",
    whitespace: "normal",
  };

  return (
    <Layout>
      <MarginContainer>
        <Container sx={sx}>
          {routes.map((route, i) => {
            const { element, routes: subRoutes } = route;
            if (element) {
              return <React.Fragment key={route.id}>{element}</React.Fragment>;
            } else if (subRoutes) {
              return (
                <ZigMenuItemSubHeader
                  key={route.id}
                  sx={{
                    padding: 0,
                    "> div": {
                      ...optionSx,
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                    },
                    ...route.sx,
                  }}
                  component="span"
                >
                  <ZigMenu
                    placement={i > subRoutes.length / 2 ? "bottom-end" : "bottom-start"}
                    matchAnchorWidth={true}
                    component={({ open }) => <SubHeaderMenuButton route={route} open={open} />}
                    id={route.id}
                    menuSx={{
                      maxHeight: "300px",
                      ...(route.dense && { ul: { padding: "15px 0" } }),
                      ...menuSx,
                    }}
                    options={subRoutes.map((r) => ({
                      ...((r.href || r.onClick) && {
                        element: (
                          <SubHeaderOption
                            route={r}
                            isSubOption={true}
                            sx={{
                              ...optionSx,
                              ...((route.dense || r.separator) && {
                                height: "auto",
                              }),
                            }}
                          />
                        ),
                        preserveStyles: true,
                        sx: {
                          color: "neutral300",
                          // Apply padding to children so that anchor is full width
                          padding: 0,
                        },
                      }),
                      ...r,
                    }))}
                  />
                </ZigMenuItemSubHeader>
              );
            } else {
              return <SubHeaderOption route={route} key={route.id} sx={optionSx} wrapMenuItem />;
            }
          })}
        </Container>
      </MarginContainer>
    </Layout>
  );
}

export default ZigSubHeader;
export * from "./types";
