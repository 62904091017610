import * as Sentry from '@sentry/react';
import { NetworkError } from 'apis/util';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { isZignaly, whitelabel } from 'whitelabel';

const denyErrors = [400, 401, 403, 'FETCH_ERROR'];

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [
          window.location.origin,
          whitelabel.baseReferralApi,
          whitelabel.baseApi,
        ],
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.thirdPartyErrorFilterIntegration({
        // Specify the application keys that you specified in the Sentry bundler plugin
        filterKeys: ['sentry'],

        // Defines how to handle errors that contain third party stack frames.
        // Possible values are:
        // - 'drop-error-if-contains-third-party-frames'
        // - 'drop-error-if-exclusively-contains-third-party-frames'
        // - 'apply-tag-if-contains-third-party-frames'
        // - 'apply-tag-if-exclusively-contains-third-party-frames'
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
    ],
    allowUrls: [
      window.location.origin,
      whitelabel.baseReferralApi,
      whitelabel.baseApi,
      process.env.PUBLIC_URL,
    ],
    // Performance Monitoring
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    environment: !isZignaly
      ? `${whitelabel.slug}-${process.env.REACT_APP_SENTRY_ENVIRONMENT}`
      : process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    // ignoreErrors: ["reading 'hasLimitReached'"],
    beforeSend(event, hint) {
      const exception = hint?.originalException as
        | Error
        | NetworkError
        | undefined;

      if ((exception as NetworkError)?.status) {
        // unwrap() calls cause an unhandled promise rejection with the error if not handled
        // Filtering out as the network error will already be captured
        if (hint.mechanism?.handled === false) return null;
        // Filter non important network errors
        if (denyErrors.includes((exception as NetworkError).status))
          return null;
      }

      return event;
    },
    normalizeDepth: 10,
  });
}
