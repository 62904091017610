import React from "react";
import { useTranslation } from "react-i18next";
import { DialogProps } from "@mui/material/Dialog";
import { Box } from "@mui/system";
import ZModal from "../index";
import ZigButton, { ZigButtonProps } from "../../../inputs/ZigButton";
import ZigTypography from "../../ZigTypography";

export type ConfirmModalProps = {
  title: string | JSX.Element;
  description?: string | JSX.Element;
  yesLabel?: string | JSX.Element;
  yesAction: () => void;
  yesButtonProps?: Partial<ZigButtonProps>;
  noButtonProps?: Partial<ZigButtonProps>;
  noLabel?: string | JSX.Element;
  noAction?: () => void;
  prefixId?: string;
  mobileFullScreen?: boolean;
};

function ConfirmModal({
  close,
  title,
  description,
  yesLabel,
  yesAction,
  yesButtonProps,
  noButtonProps,
  noLabel,
  noAction,
  ...props
}: {
  close: () => void;
} & ConfirmModalProps &
  DialogProps): React.ReactElement {
  const { t } = useTranslation("zignaly-ui", { keyPrefix: "ZModal" });

  return (
    <ZModal wide {...props} close={close} title={title}>
      {!!description && <ZigTypography>{description}</ZigTypography>}

      <Box sx={{ textAlign: "center" }}>
        <ZigButton
          id={props.id && `${props.id}-confirm`}
          variant={"contained"}
          type="submit"
          size={"large"}
          onClick={() => {
            yesAction();
            close();
          }}
          {...(yesButtonProps || {})}
          sx={{ mx: 0.5, mt: 2, ...(yesButtonProps?.sx || {}) }}
        >
          {yesLabel || t("confirm")}
        </ZigButton>

        <ZigButton
          id={props.id && `${props.id}-cancel`}
          variant={"outlined"}
          type="submit"
          size={"large"}
          onClick={() => {
            noAction?.();
            close();
          }}
          {...(noButtonProps || {})}
          sx={{ mx: 0.5, mt: 2, ...(noButtonProps?.sx || {}) }}
        >
          {noLabel || t("cancel")}
        </ZigButton>
      </Box>
    </ZModal>
  );
}

export default ConfirmModal;
