import { darken, styled, Theme } from "@mui/system";

const backgroundColor = (theme: Theme) => darken(theme.palette.backgrounds.neutralBg, 0x1 / 0xff);

export const Layout = styled("div")<{ error?: string; loading?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .input-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content !important;
    position: relative;

    ${({ loading }) => `
      ${
        loading
          ? `
        div:last-of-type {
          height: 64px;
          border-radius: 8px;
          background: unset;
        }
        `
          : ``
      }
    `}
    div:first-child {
      display: flex;
      gap: 8px;
    }

    input {
      // 1Password autofill fix
      background: ${({ theme }) =>
        `linear-gradient(90deg, ${backgroundColor(theme)} 0%, ${backgroundColor(theme)} 100%)`};
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.palette.neutral600} !important;
      color: ${(props) => `${props.theme.palette.neutral100}`};
      width: 64px !important;
      font-family: ${
        // @ts-ignore
        (props) => props.theme.typography.fontFamily
      };
      height: 64px !important;
      font-size: 26px;

      @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        width: 45px !important;
        max-width: calc((100vw - 120px) / 6);
        height: 54px !important;
      }
    }

    input:not(:placeholder-shown):valid {
      background-image: linear-gradient(
          to bottom,
          ${(props) => props.theme.palette.backgrounds.neutralBg},
          ${(props) => props.theme.palette.backgrounds.neutralBg}
        ),
        ${(props) => props.theme.palette.backgrounds.buttonPrimary};
      background-origin: border-box, border-box;
      background-clip: padding-box, border-box;
      border: 1px solid transparent !important;
    }
  }

  ${(props) => `
    ${
      props.error
        ? `
       .input-box {
          margin-bottom: 10px;
          input {
            border-color: ${props.theme.palette.red} !important;
          }
       }
      `
        : ``
    }
  `}
`;
