import '@khmyznikov/pwa-install';
import React from 'react';
import { whitelabel } from '../whitelabel';
import { junkyard } from 'util/junkyard';
import { differenceInDays } from 'date-fns';

const shouldNotifyPwa = () => {
  const pwaNotified = junkyard.get('pwaNotified');
  let shouldNotify = !pwaNotified;

  if (
    !shouldNotify &&
    differenceInDays(new Date(), new Date(pwaNotified)) >= 30
  ) {
    shouldNotify = true;
  }

  if (shouldNotify) {
    junkyard.set('pwaNotified', new Date().toISOString());
  }

  return shouldNotify;
};
const notifyPwa = shouldNotifyPwa();

const PwaInstall = (): JSX.Element => {
  if (!notifyPwa) {
    return null;
  }

  return (
    <pwa-install
      id='pwa-install'
      icon={whitelabel.pwaLogo}
      manifest-url={whitelabel.manifest}
    ></pwa-install>
  );
};

export default PwaInstall;
